<template>
  <div>
    <div class="modal fade" id="modal-form-cargue-masivo">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cargar Archivo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalCargueMasivo"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- <div class="row">
              <div class="form-group col-md-12 center">
                <label>Bloque</label>
                <v-select
                  :class="[
                    $v.form.bloque_id.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="bloque"
                  placeholder="Nombre"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.bloques"
                  :filterable="false"
                  @search="buscarBloque"
                  @input="selectBloque()"
                ></v-select>
              </div>
            </div> -->
            <div class="row">
              <div class="btn btn-default image-preview-input col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <button
                      type="button"
                      class="btn btn-sm alert-default-primary mb-1 float-left"
                      @click="exportarPlantillaCargue()"
                    >
                      Descargar Plantilla
                      <i class="fas fa-download"></i>
                    </button>
                  </div>
                  <div class="col-md-6" v-if="form.file">
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary mb-1 float-right"
                      @click="limpiarArchivo"
                    >
                      <i class="fas fa-eraser"></i>
                    </button>
                  </div>
                </div>

                <input
                  type="file"
                  id="file"
                  ref="file"
                  class="form-control btn"
                  v-on:change="elegirDirectorio()"
                  accept=".XLSX, .CSV"
                  :class="
                    $v.form.file.$invalid
                      ? 'is-invalid alert-default-danger'
                      : 'is-valid alert-default-success'
                  "
                />
              </div>
              <div
                class="form-group col-md-2 float-left"
                v-if="form.file"
              ></div>
            </div>
            <br />
            <div class="row">
              <div class="form-group col-md-12">
                <div class="card card-outline card-navy">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">
                      <h6>
                        <i class="fas fa-file-signature"></i>
                        Nota:
                      </h6>
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <ol>
                        <li>
                          El formato debe estar completo sin ningún campo en
                          blanco.
                        </li>
                        <li>
                          El campo de Fecha Reporte debe llevar un formato
                          (“yyyy–mm-dd hh:mm:ss”).
                        </li>
                        <li>La placa debe estar en mayúscula.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <button
                type="button"
                class="btn btn-primary"
                @click="importExcel()"
                v-show="!$v.form.$invalid"
              >
                Importar
                <i class="fas fa-cloud-upload-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
/* import vSelect from "vue-select"; */
export default {
  name: "TifConciliacionesCargueMasivo",
  components: {
    /* vSelect, */
  },

  data() {
    return {
      accion: 1,
      cargue_id: null,
      tramas: {},
      bloque: [],
      form: {
        file: null,
        /* bloque_id: null, */
      },
      listasForms: {
        bloques: [],
      },
    };
  },
  validations: {
    form: {
      file: {
        required,
      },
      /*  bloque_id: {
        required,
      }, */
    },
  },

  methods: {
    llenarModalImport(cargue) {
      this.tramas = cargue;
    },

    elegirDirectorio() {
      this.form.file = this.$refs.file.files[0];
    },

    /*    buscarBloque(search) {
      let me = this;
      this.listasForms.bloques = [];
      if (search != "") {
        var url = "api/admin/bloques/listaSearch?bloque=" + search;
        axios
          .get(url)
          .then(function (response) {
            me.listasForms.bloques = response.data;
          })
          .catch(function (e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    }, */

    /*  selectBloque() {
      this.form.bloque_id = "";
      if (this.bloque) {
        this.form.bloque_id = this.bloque.id;
      }
    }, */

    exportarPlantillaCargue() {
      this.cargando = true;
      console.log(this.form.bloque_id);
      axios({
        method: "GET",
        url: "/api/tif/conciliaciones/exportPlantillaCargue",
        params: {
          bloque_id: this.form.bloque_id,
        },
      })
        .then((response) => {
          console.log("entro");
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error inesperado " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    importExcel() {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("file", this.form.file);

      axios
        .post("/api/tif/conciliaciones/importExcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$refs.closeModalCargueMasivo.click();
          this.limpiarModal();
          this.$parent.getIndex();
          this.$swal({
            icon: res.data.status == 0 ? "warning" : "success",
            title: res.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    limpiarArchivo() {
      this.form.file = null;
      document.getElementById("file").value = "";
    },

    limpiarModal() {
      this.form.file = null;
      document.getElementById("file").value = "";
      this.$parent.getIndex();
    },
  },

  mounted() {},
};
</script>